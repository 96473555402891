import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import styled from 'styled-components';

const ProjectPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0e1624;
  color: #ffffff;
  font-family: 'Garet', sans-serif;
  
  overflow: hidden; /* Prevent scrolling */
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProjectImage = styled.img`
  max-width: 90%;  
  max-height: 70vh; 
  height: auto;     
`;

const DetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
`;

const Title = styled.h1`
  font-family: 'Bodoni FLF', serif;
  font-size: 40px;
  font-weight: bold;
  margin: 0 0 10px 0;
`;

const Description = styled.p`
  font-family: 'Garet', sans-serif;
  font-size: 16px;
  margin: 10px 0;
  max-width: 600px;

`;

const Link = styled.a`
  color: #ffffff;
  text-decoration: none;
  margin: 5px 0;

  &:hover {
    text-decoration: underline;
  }
`;

const HomeButton = styled.button`
  background-color: transparent; /* Use a transparent background */
  border: none; /* Remove border */
  color: #ffffff; /* White text */
  font-family: 'Bodoni FLF', serif;
  font-size: 20px;
  cursor: pointer; /* Pointer cursor on hover */
  position: absolute; /* Position it at the top */
  top: 20px; /* Space from the top */
  left: 20px; /* Space from the left */
  

`;

const Footer = styled.footer`
  font-family: 'Garet', sans-serif;
  position: absolute;
  bottom: 20px;
  font-size: 12px;
`;

const EmailLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ProjectPage = ({ project }) => {
  const navigate = useNavigate(); // Use navigate hook

  return (
    <ProjectPageContainer>
      <HomeButton onClick={() => navigate('/')}>VAGUE.DEV</HomeButton> {/* Home Button */}
      <ImageContainer>
        <ProjectImage src={project.image_path} alt={project.project_name} />
      </ImageContainer>
      <DetailsContainer>
        <Title>{project.project_name}</Title>
        <Description>{project.description}</Description>
        {project.github_url && (
          <Link href={project.github_url} target="_blank" rel="noopener noreferrer">
            GitHub Repository
          </Link>
        )}
        {project.hosted_url && (
          <Link href={project.hosted_url} target="_blank" rel="noopener noreferrer">
            {project.hosted_text}
          </Link>
        )}
      </DetailsContainer>
      <Footer>
        <EmailLink href="mailto:info@vague.dev">info@vague.dev</EmailLink>
      </Footer>
    </ProjectPageContainer>
  );
};

export default ProjectPage;
