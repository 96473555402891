import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; 

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0e1624;
  color: #ffffff;
  font-family: 'Garet', sans-serif;
`;

const Title = styled.h1`
  font-family: 'Bodoni FLF', serif;
  font-size: 60px;
  font-weight: bold;
  margin: 0;
`;


const HomeButton = styled.button`
  background-color: transparent; /* Use a transparent background */
  border: none; /* Remove border */
  color: #ffffff; /* White text */
  font-family: 'Bodoni FLF', serif;
  font-size: 20px;
  cursor: pointer; /* Pointer cursor on hover */
  position: absolute; /* Position it at the top */
  top: 20px; /* Space from the top */
  left: 20px; /* Space from the left */
  

`;

const Footer = styled.footer`
  font-family: 'Garet', sans-serif;
  position: absolute;
  bottom: 20px;
  font-size: 12px;
`;

const Subtitle = styled.p`
  font-family: 'Garet', sans-serif;
  font-size: 16px;
  margin: 10px 0;
  max-width: 75%;
    text-align: center;

`;

const EmailLink = styled.a`
  color: #ffffff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SupportPage = () => {
  const navigate = useNavigate();

  return (<HomePageContainer>
          <HomeButton onClick={() => navigate('/')}>VAGUE.DEV</HomeButton> {/* Home Button */}

    <Title>Support</Title>
    <Subtitle>please find an email address at the bottom of the page</Subtitle>
    <Footer>
      <EmailLink href="mailto:info@vague.dev">info@vague.dev</EmailLink>
    </Footer>
  </HomePageContainer>
  );
};

export default SupportPage;
