import React from 'react';
import styled from 'styled-components';

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0e1624;
  color: #ffffff;
  font-family: 'Garet', sans-serif;
`;

const Title = styled.h1`
  font-family: 'Bodoni FLF', serif;
  font-size: 60px;
  font-weight: bold;
  margin: 0;
`;

const Subtitle = styled.p`
  font-family: 'Garet', sans-serif;
  font-size: 16px;
  margin: 10px 0;
`;

const Footer = styled.footer`
  font-family: 'Garet', sans-serif;
  position: absolute;
  bottom: 20px;
  font-size: 12px;
`;

const EmailLink = styled.a`
  color: #ffffff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const HomePage = () => (
  <HomePageContainer>
    <Title>VAGUE.DEV</Title>
    <Subtitle>where undefined becomes refined</Subtitle>
    <Footer>
      <EmailLink href="mailto:info@vague.dev">info@vague.dev</EmailLink>
    </Footer>
  </HomePageContainer>
);

export default HomePage;
