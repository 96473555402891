import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import ProjectPage from './components/ProjectPage';
import NotFound from './components/NotFound';
import SupportPage from './components/SupportPage';

function App() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fetch your JSON data
    fetch('/projects.json')
      .then((response) => response.json())
      .then((data) => setProjects(data))
      .catch((error) => console.error('Error fetching projects:', error));
  }, []);
  
  return (
    <Router>
      <Routes>
        {/* Default route for home page */}
        <Route path="/" element={<HomePage />} />
        <Route path="/support" element={<SupportPage />} />
        
        {projects.map((project) => (
          <Route
            key={project.path}
            path={`/${project.path}`}
            element={<ProjectPage project={project} />}
          />
        ))}
        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
